import React from "react";
import { UserOutlined, AppstoreOutlined, ApiOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useUserData } from "./useUserToken";

const useUserAccess = () => {
  const navigate = useNavigate();
  const commonMenuItems = [
    {
      label: "Serviços",
      icon: <AppstoreOutlined />,
      key: "services",
      onClick: () => navigate("/services"),
    },
    {
      label: "Tokens",
      icon: <ApiOutlined />,
      key: "tokens",
      onClick: () => navigate("/tokens"),
    },
  ];
  const adminMenuItems = [
    {
      label: "Serviços",
      icon: <AppstoreOutlined />,
      key: "services",
      onClick: () => navigate("/services"),
    },
    {
      label: "Usuários",
      icon: <UserOutlined />,
      key: "users",
      onClick: () => navigate("/users"),
    },
    {
      label: "Tokens",
      icon: <ApiOutlined />,
      key: "tokens",
      onClick: () => navigate("/tokens"),
    },
    // {
    //   label: "Metadados",
    //   icon: <FileOutlined />,
    //   key: "metadata",
    //   onClick: () => navigate("/metadata"),
    // },
  ];

  const superAdminMenuItems = [
    ...adminMenuItems,
    // {
    //   label: "Configurações",
    //   icon: <SettingOutlined />,
    //   key: "settings",
    //   onClick: () => navigate("/settings"),
    // },
  ];

  const access = role => {
    switch (role) {
      case "usuario":
        return commonMenuItems;
      case "admin_nacional":
        return adminMenuItems;
      case "admin_estadual":
        return adminMenuItems;
      case "superadmin":
        return superAdminMenuItems;
      default:
        return commonMenuItems;
    }
  };

  const userData = useUserData("userData");
  const user = JSON.parse(userData);
  const menuList = access(user.role);
  const { role } = user;
  const disableTokenButton = role === "usuario";
  const showTokenDeleteBtn = role !== "usuario";
  return { user, role, menuList, disableTokenButton, showTokenDeleteBtn };
};

export { useUserAccess };
