/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable dot-notation */
/* eslint-disable react/prop-types */
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  message,
  Spin,
  Button,
  // Space,
  Typography,
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  Checkbox,
  Divider,
  Tabs,
  Row,
  Col,
} from "antd";
import styles from "./TokenModal.module.scss";
import {
  createToken,
  getAllAvailableServices,
  getAllTokens,
} from "../../../api/Tokens/tokens.service";

// List of Brazilian States
const brazilianStates = [
  { code: "AC", name: "Acre" },
  { code: "AL", name: "Alagoas" },
  { code: "AP", name: "Amapá" },
  { code: "AM", name: "Amazonas" },
  { code: "BA", name: "Bahia" },
  { code: "CE", name: "Ceará" },
  { code: "DF", name: "Distrito Federal" },
  { code: "ES", name: "Espírito Santo" },
  { code: "GO", name: "Goiás" },
  { code: "MA", name: "Maranhão" },
  { code: "MT", name: "Mato Grosso" },
  { code: "MS", name: "Mato Grosso do Sul" },
  { code: "MG", name: "Minas Gerais" },
  { code: "PA", name: "Pará" },
  { code: "PB", name: "Paraíba" },
  { code: "PR", name: "Paraná" },
  { code: "PE", name: "Pernambuco" },
  { code: "PI", name: "Piauí" },
  { code: "RJ", name: "Rio de Janeiro" },
  { code: "RN", name: "Rio Grande do Norte" },
  { code: "RS", name: "Rio Grande do Sul" },
  { code: "RO", name: "Rondônia" },
  { code: "RR", name: "Roraima" },
  { code: "SC", name: "Santa Catarina" },
  { code: "SP", name: "São Paulo" },
  { code: "SE", name: "Sergipe" },
  { code: "TO", name: "Tocantins" },
];

// eslint-disable-next-line react/function-component-definition
const TokenModal = ({ isVisible, closeModal, mountTable }) => {
  const { Option } = Select;

  const [availableServices, setAvailableServices] = useState();
  const [permissions, setPermissions] = useState();

  const [isCreating, setIsCreating] = useState(false);
  const [loading, setLoading] = useState(false);

  const checkboxes = [
    { label: "Ler (Endpoints GET)", value: "read", checked: true },
    { label: "Criar (Endpoints POST)", value: "create", checked: true },
    { label: "Atualizar (Endpoints PUT)", value: "update", checked: true },
    { label: "Deletar (Endpoints DELETE)", value: "delete", checked: true },
  ];

  useEffect(() => {
    if (isVisible) {
      getAllAvailableServices()
        .then(res => {
          setAvailableServices(res);
          const initialPermissions = {};
          res.forEach(service => {
            initialPermissions[service["name"]] = checkboxes.map(cb => ({
              ...cb,
              checked: true,
            }));
          });
          setPermissions(initialPermissions);
        })
        .catch(err => {
          message.error(err.message);
        });
    }
  }, [isVisible]);

  const refresh = () => {
    getAllTokens()
      .then(response => {
        mountTable(response);
      })
      .catch(err => {
        message.error(err.message);
      });
  };

  const onFinish = data => {
    setLoading(true);

    const finalPermissions = [];
    for (const svcName of Object.keys(permissions)) {
      finalPermissions.push({
        service_name: svcName,
        read: permissions[svcName].find(cb => cb.value === "read").checked,
        create: permissions[svcName].find(cb => cb.value === "create").checked,
        update: permissions[svcName].find(cb => cb.value === "update").checked,
        delete: permissions[svcName].find(cb => cb.value === "delete").checked,
      });
    }

    data["permissions"] = finalPermissions;
    data["domains"] = data["domains"].split(",");
    data["expiration_date"] = data["expiration_date"].format("YYYY-MM-DD[T]HH:mm:ss");

    console.log(JSON.stringify(data));

    setIsCreating(true);
    createToken(JSON.stringify(data))
      .then(() => {
        message.success("Token criado com sucesso!");
        closeModal();
        setLoading(false);
        refresh();
      })
      .catch(err => {
        message.error(err.message);
      })
      .finally(() => setIsCreating(false));
  };

  const onFinishFailed = errorInfo => {
    console.log("onFinishFailed data", errorInfo);
  };

  const checkboxClicked = (serviceName, value) => {
    const updatedCheckboxes = [
      {
        label: "Ler (Endpoints GET)",
        value: "read",
        checked: value.includes("read"),
      },
      {
        label: "Criar (Endpoints POST)",
        value: "create",
        checked: value.includes("create"),
      },
      {
        label: "Atualizar (Endpoints PUT)",
        value: "update",
        checked: value.includes("update"),
      },
      {
        label: "Deletar (Endpoints DELETE)",
        value: "delete",
        checked: value.includes("delete"),
      },
    ];
    const newPermissions = { ...permissions };
    newPermissions[serviceName] = updatedCheckboxes;
    setPermissions(newPermissions);
  };

  return (
    <Modal
      transitionName=""
      maskTransitionName=""
      title="Criação de token"
      visible={isVisible}
      onCancel={() => closeModal()}
      footer={[
        <Button disabled={isCreating} type="primary" form="myForm" key="submit" htmlType="submit">
          <Spin spinning={loading} size="small" />
          Criar
        </Button>,
        <Button disabled={isCreating} key="back" onClick={() => closeModal()}>
          Cancelar
        </Button>,
      ]}
    >
      <Form
        id="myForm"
        name="myForm"
        layout="horizontal"
        initialValues={{
          expiration_date: moment().add(12, "months"),
          token_type: "HOMOLOG",
          domains: "*",
          enabled: true,
          state: "SC",
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Alias"
              name="alias"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className={styles.custom_form_padding}
              tooltip="Nome que remeta a aplicação que o token irá ser utilizado"
              rules={[
                {
                  required: true,
                  message: "Por favor, informe o alias!",
                },
              ]}
            >
              <Input disabled={isCreating} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="E-mail do responsável pelo token"
              name="responsible_email"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className={styles.custom_form_padding}
              tooltip="Quem será responsável pelo uso do token e responderá por ele"
              rules={[
                {
                  required: true,
                  message: "Por favor, informe o e-mail do responsável!",
                },
                {
                  type: "email",
                  message: "Por favor, insira um e-mail válido!",
                },
              ]}
            >
              <Input disabled={isCreating} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Tipo de token"
              name="token_type"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className={styles.custom_form_padding}
              rules={[{ required: true }]}
            >
              <Select disabled={isCreating}>
                <Option value="HOMOLOG">Homologação</Option>
                <Option value="PRODUCTION">Produção</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Data de expiração"
              name="expiration_date"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className={styles.custom_form_padding}
              rules={[
                {
                  required: true,
                  message: "Por favor, selecione a data de expiração!",
                },
              ]}
            >
              <DatePicker
                className={styles.data_placeholder_input}
                disabled={isCreating}
                format="DD/MM/YYYY"
                placeholder="DD/MM/YYYY"
              />
            </Form.Item>
          </Col>
        </Row>

        {/* New Row for Estado with Search */}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Estado"
              name="state"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className={styles.custom_form_padding}
              tooltip="Selecione o estado que o token será atrelado"
              rules={[
                {
                  required: true,
                  message: "Por favor, selecione o estado!",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Selecione o estado"
                optionFilterProp="children"
                disabled={isCreating}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase()) ||
                  option.value.toLowerCase().includes(input.toLowerCase())
                }
              >
                {brazilianStates.map(state => (
                  <Option key={state.code} value={state.code}>
                    {`${state.name} (${state.code})`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Domínios"
              name="domains"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className={styles.custom_form_padding}
              tooltip="Domínios separados por vírgula. Pode conter wildcard (ex: http://localhost:* ou http:*.sebrae-sc.com.br)"
              rules={[{ required: true, message: "Por favor, informe o domínio!" }]}
            >
              <Input disabled={isCreating} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="SAS Token"
              name="sas_token"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className={styles.custom_form_padding}
              tooltip="Token da aplicação SAS que será utilizado por esse token."
              rules={[{ required: false }]}
            >
              <Input disabled={isCreating} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Habilitado para uso"
              name="enabled"
              valuePropName="checked"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true }]}
            >
              <Checkbox disabled={isCreating} />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <div direction="vertical" align="center">
          <Typography.Title level={4} align="left">
            Permissões
          </Typography.Title>
          <Tabs tabPosition="left">
            {availableServices &&
              permissions &&
              availableServices.map((service, index) => (
                <React.Fragment key={index}>
                  <Tabs.TabPane
                    tab={service["alias"]}
                    key={index}
                    className={styles.tabs_align_left}
                  >
                    <Checkbox.Group
                      className={styles.checkbox_group}
                      disabled={isCreating}
                      defaultValue={["read", "create", "update", "delete"]}
                      options={permissions[service["name"]].map(cb => ({
                        label: cb.label,
                        value: cb.value,
                      }))}
                      onChange={e => checkboxClicked(service["name"], e)}
                    />
                  </Tabs.TabPane>
                </React.Fragment>
              ))}
          </Tabs>
        </div>
      </Form>
    </Modal>
  );
};

export { TokenModal };
