import React from "react";
// eslint-disable-next-line import/no-unresolved
import { PageLayout } from "components/PageLayout";
// eslint-disable-next-line import/no-unresolved
import { Row, Col, Typography } from "antd";
import styles from "./About.module.scss";

function About() {
  return (
    <PageLayout title="Learning Intelligence System (LIS)" subTitle="Versão 1.0.0">
      <section>
        <h2>Sobre a LIS</h2>
        <Row className={styles.row_margin}>
          <Col xs={24} className={styles.container}>
            <Typography>
              A LIS, Learning Intelligence System, é uma arquitetura de serviços de inteligência
              artificial capaz de compreender o comportamento do cliente e interagir com ele
              propondo os melhores caminhos para o seu desenvolvimento. <br />
              Nesta versão foram disponibilizados os serviços:
            </Typography>
            <ol>
              <li>Reconhecimento Facial;</li>
              <li>Recomendação;</li>
              <li>Transcrição de áudio para texto;</li>
              <li>Análise de sentimentos;</li>
              <li>Análise de emoções;</li>
              <li>Smart Chatbot;</li>
              <li>Redes de relacionamento.</li>
            </ol>
          </Col>
        </Row>
      </section>
      <Row gutter={16} className={styles.stretch}>
        <Col className={`${styles.flex_column} gutter-row`} xs={24} md={12}>
          <h2>Equipe responsável</h2>
          <div className={styles.container}>
            <Typography>
              Daniella Vieira{" "}
              <span className={styles.italic}>&lt;daniella.vieira@sc.sebrae.com.br&gt;</span>
              <br /> <span className={styles.description}>Líder técnica</span>
              <br />{" "}
              <span className={styles.description}>
                Gerência de Tecnologia da Informação do Sebrae SC
              </span>
              <br />
              <br /> Carlos Douglas Moreira{" "}
              <span className={styles.italic}>&lt;cdouglas@sc.sebrae.com.br&gt;</span>
              <br />{" "}
              <span className={styles.description}>
                Gerente da Gerência de Tecnologia da Informação
              </span>
              <br />{" "}
              <span className={styles.description}>
                Gerência de Tecnologia da Informação do Sebrae SC
              </span>
              <br />
              <br /> Denner Felipe Silva Ferreira{" "}
              <span className={styles.italic}>&lt;denner.ferreira@sc.sebrae.com.br&gt;</span>
              <br /> <span className={styles.description}>Gestão Técnica do Contrato</span>
              <br />
              <br /> Daniel Gonçalves Lopes{" "}
              <span className={styles.italic}>&lt;daniel.lopes@sc.sebrae.com.br&gt;</span>
              <br />{" "}
              <span className={styles.description}>
                Apoio Técnico - Engenharia de Dados / Datalake
              </span>
            </Typography>
          </div>
        </Col>
        <Col className={`${styles.flex_column} gutter-row`} xs={24} md={12}>
          <h2>Apoiadores</h2>
          <div className={styles.container}>
            <Typography>
              Andre Luis Araujo Maciel <br />
              <span className={styles.description}>
                Unidade de Gestão Estratégica do Sebrae Nacional
              </span>
              <br />
              <br />
              Douglas Luís Três
              <br />
              <span className={styles.description}>Gerência de Atendimento Empresarial</span>
              <br />
              <br />
              Fausto Ricardo Keske Cassemiro <br />
              <span className={styles.description}>
                Unidade de Gestão Estratégica do Sebrae Nacional
              </span>
              <br />
              <br />
              Marcos Regueira
              <br />
              <span className={styles.description}>Assessoria de projetos especiais</span>
              <br />
              <br />
              Pedro Pirajá
              <br />
              <span className={styles.description}>Assessoria jurídica</span>
              <br />
              <br />
              Tomaz Back Carrijo <br />
              <span className={styles.description}>
                Unidade de Gestão Estratégica do Sebrae Nacional
              </span>
            </Typography>
          </div>
        </Col>
      </Row>
      <Row gutter={16} className={styles.row_margin}>
        <Col className={`${styles.flex_column} gutter-row`} xs={24} md={12}>
          <h2>Diretoria do Sebrae SC</h2>
          <article className={styles.container}>
            <Typography>
              Carlos Henrique Ramos Fonseca <br />
              <span className={styles.description}>Diretor Superintendente</span>
              <br />
              <br />
              Anacleto Angelo Ortigara <br />
              <span className={styles.description}>Diretor Administração e Finanças</span>
              <br />
              <br />
              Fabio Burigo Zanuzzi <br />
              <span className={styles.description}>Diretor Técnico</span>
            </Typography>
          </article>
        </Col>
        <Col className="gutter-row" xs={24} md={12}>
          <h2>Equipe fornecedora</h2>
          <article className={styles.container}>
            <Typography>
              Itera Inovação e Desenvolvimento Tecnológico
              <br />
              <a
                className={styles.italic}
                href="http://www.itera.com.br"
                target="_blank"
                rel="noreferrer"
              >
                www.itera.com.br
              </a>
              <br />
              <br />
              Dhiogo Correa
              <br />
              <span className={styles.description}>Chief AI Officer</span>
              <br />
              <br />
              Camila Vaccari Sundermann
              <br />
              <span className={styles.description}>Especialista em Inteligência Artifical</span>
              <br />
              <br />
              Val Fontanette
              <br />
              <span className={styles.description}>Chief Operating Officer</span>
              <br />
              <br />
              Leonardo Comelli
              <br />
              <span className={styles.description}>Chief Technology Officer</span>
              <br />
              <br />
              Hamilton Agar
              <br />
              <span className={styles.description}>Gestão de Projetos</span>
              <br />
              <br />
              Marco Antonio Pereira
              <br />
              <span className={styles.description}>CEO e Gestor da Conta</span>
              <br />
              <br />
              Renato Carrião
              <br />
              <span className={styles.description}>Diretor Comercial</span>
              <br />
              <br />
              <span className={styles.description}>Desenvolvedores (Ciclo 2)</span>
              <br />
              Isaias da Silva
              <br />
              Filipe Pedroso da Silva
              <br />
              Victor Almeida Nicacio Queiroz
              <br />
              Vinícius Borges
              <br />
              <br />
              <span className={styles.description}>Desenvolvedores (Ciclo 1)</span>
              <br />
              Gustavo Dias
              <br />
              George Martins
              <br />
              Otto Alves Antoniolli
              <br />
              Jonas Henrique Rodrigues
              <br />
              Gabriel Cheban
              <br />
              João Marcelo Simão de Castro
              <br />
              Steve Ataucuri Cruz
              <br />
              Vitor Bigelli
              <br />
            </Typography>
          </article>
        </Col>
      </Row>
    </PageLayout>
  );
}

export { About };
