/* eslint-disable eqeqeq */
/* eslint-disable dot-notation */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { message, Spin, Table, Button, Space, Typography, Tooltip } from "antd";
import {
  DeleteOutlined,
  CopyOutlined,
  UnorderedListOutlined,
  EditOutlined,
} from "@ant-design/icons";
// eslint-disable-next-line import/no-unresolved
import { useUserAccess } from "hooks/useUserAccess";
// eslint-disable-next-line import/no-unresolved
import { PageLayout } from "components/PageLayout";
// eslint-disable-next-line import/no-unresolved
import { JsonModal } from "components/JsonModal";
// eslint-disable-next-line import/no-unresolved
import { deleteToken, getAllTokens, getTokenDetails } from "api/Tokens/tokens.service";
// eslint-disable-next-line import/no-unresolved
import { ConfirmModal } from "components/ConfirmModal";
import styles from "./Tokens.module.scss";

import { TokenModal } from "./TokenModal/TokenModal";
import { TokenModalEditExpirationDate } from "./TokenModalEditExpirationDate/TokenModalEditExpirationDate";

// eslint-disable-next-line react/function-component-definition
const Tokens = () => {
  const [loading, setLoading] = useState(true);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const { disableTokenButton, showTokenDeleteBtn } = useUserAccess();
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [detailsData, setDetailsData] = useState();
  const [selectedToken, setSelectedToken] = useState();

  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState();

  const columns = [
    {
      title: "Alias",
      dataIndex: "alias",
      key: "alias",
      sorter: {
        compare: (a, b) => a.alias.localeCompare(b.alias),
        multiple: 3,
      },
    },
    {
      title: "Email do responsável",
      dataIndex: "responsible_email",
      key: "responsible_email",
      sorter: {
        compare: (a, b) => a.responsible_email.localeCompare(b.responsible_email),
        multiple: 2,
      },
    },
    {
      title: "Estado",
      dataIndex: "state",
      key: "state",
      sorter: {
        compare: (a, b) => a.responsible_email.localeCompare(b.responsible_email),
        multiple: 2,
      },
    },
    {
      title: "Data de expiração",
      dataIndex: "expiration_date",
      key: "expiration_date",
      sorter: {
        compare: (a, b) => {
          const [aDay, aMonth, aYear] = a.expiration_date.split("/");
          const [bDay, bMonth, bYear] = b.expiration_date.split("/");
          return moment(new Date(bYear, bMonth, bDay)).diff(new Date(aYear, aMonth, aDay));
        },
        multiple: 1,
      },
      render: (text, record) => (
        <Typography.Text>
          {record.expiration_date}
          <Button
            onClick={() => editExpirationDate(record)}
            shape="circle"
            type="ghost"
            icon={<EditOutlined />}
            style={{ marginLeft: 10 }}
          />
        </Typography.Text>
      ),
    },
    {
      title: "Token",
      dataIndex: "token",
      key: "token",
      render: (text, record) => (
        <Typography.Text>
          {/* {record['token'].substring(0, 22) + '...  '} */}
          {record.token.length > 12 ? `${record.token.substring(0, 12)}... ` : record.token}
          <Tooltip placement="topLeft" title="copiar token">
            <Button
              onClick={() => copyToken(record["token"])}
              shape="circle"
              type="ghost"
              icon={<CopyOutlined />}
            />
          </Tooltip>
        </Typography.Text>
      ),
    },
    {
      title: "SAS Token",
      dataIndex: "sas_token",
      key: "sas_token",
      render: (text, record) => (
        <Typography.Text>
          {record?.sas_token?.length > 12
            ? `${record?.sas_token?.substring(0, 12)}... `
            : record?.sas_token}
        </Typography.Text>
      ),
    },
    {
      title: "Status",
      dataIndex: "enabled",
      key: "enabled",
      render: (text, record) => (
        <Typography.Text>{record?.enabled ? "Ativo" : "Inativo"}</Typography.Text>
      ),
    },
    {
      title: "Ações",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip placement="topLeft" title="detalhes do registro">
            <Button
              onClick={() => tokenDetails(record.id)}
              shape="circle"
              type="ghost"
              icon={
                <UnorderedListOutlined alt="detalhes do registro" title="detalhes do registro" />
              }
              // icon={
              //   <img
              //     style={{ width: "20px" }}
              //     src="./visualizar_token.svg"
              //     alt="detalhes do registro"
              //     title="detalhes do registro"
              //   />
              // }
            />
          </Tooltip>
          {showTokenDeleteBtn && (
            <Tooltip placement="topLeft" title="excluir">
              <Button
                shape="circle"
                type="ghost"
                icon={<DeleteOutlined style={{ color: "#ff0000" }} />}
                onClick={() => {
                  setSelectedToken(record);
                  handleOpenModalConfirm();
                }}
              />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  const [data, setData] = useState();

  useEffect(() => {
    getAllTokens()
      .then(res => {
        mountTable(res);
      })
      .catch(err => {
        message.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (currentRecord) {
      setIsEditModalVisible(true);
    }
  }, [currentRecord]);

  const mountTable = tokens => {
    tokens = tokens.map(user => {
      user["key"] = user.id;
      user["expiration_date"] = moment(user.expiration_date).format("DD/MM/YYYY");

      return user;
    });
    setData(tokens);
  };

  const editExpirationDate = record => {
    setCurrentRecord(record);
  };

  const copyToken = token => {
    navigator.clipboard.writeText(token);
    message.info("Token copiado para área de transferência");
  };

  const tokenDetails = id => {
    setDetailsLoading(true);
    setIsDetailsModalVisible(true);
    getTokenDetails(id)
      .then(res => {
        setDetailsData(res);
        refresh();
      })
      .catch(err => {
        console.log(err);
        message.error(err.message);
      })
      .finally(() => setDetailsLoading(false));
  };

  const tokenDelete = id => {
    setLoading(true);
    deleteToken(id)
      .then(() => {
        const newData = [...data];

        const indx = newData.map(d => d.id).indexOf(id);
        if (indx) {
          newData.splice(indx, 1);
          setData(newData);
        }
        message.success("Token removido com sucesso!");
      })
      .catch(err => {
        message.error(err.message);
      })
      .finally(() => {
        refresh();
        setLoading(false);
      });
  };

  const refresh = () => {
    getAllTokens()
      .then(response => {
        mountTable(response);
      })
      .finally(setLoading(false));
  };

  const closeModal = () => {
    setIsDetailsModalVisible(false);
    setLoading(true);
    refresh();
  };

  const handleCloseConfirmModal = () => setOpenConfirmModal(prevState => !prevState);

  const handleOpenModalConfirm = () => setOpenConfirmModal(true);

  // eslint-disable-next-line no-shadow
  const handleTokenDelete = selectedToken => {
    tokenDelete(selectedToken.id);
    handleCloseConfirmModal();
  };

  return (
    <PageLayout
      title="Tokens"
      subTitle="Gerencie os tokens de aplicação para acesso aos serviços da LIS."
    >
      {!loading && (
        <div className={styles.token_button}>
          <Button
            type="primary"
            disabled={disableTokenButton}
            onClick={() => setIsCreateModalVisible(true)}
          >
            Criar token
          </Button>
        </div>
      )}
      <div className={styles.tokens_listing}>
        <Spin className={styles.services_spinner} spinning={loading} size="large" />
        {columns && data && (
          <Table
            style={{ width: "100%", marginRight: 10 }}
            dataSource={data}
            columns={columns}
            pagination={{ showSizeChanger: data.length > 10 }}
          />
        )}
      </div>
      <JsonModal
        jsonData={detailsData}
        isLoading={detailsLoading}
        isVisible={isDetailsModalVisible}
        closeModal={() => closeModal()}
      />
      <TokenModal
        className={styles.token_modal}
        isVisible={isCreateModalVisible}
        mountTable={mountTable}
        closeModal={() => setIsCreateModalVisible(false)}
      />

      <TokenModalEditExpirationDate
        className={styles.token_modal}
        isVisible={isEditModalVisible}
        mountTable={mountTable}
        closeModal={() => setIsEditModalVisible(false)}
        token={currentRecord}
      />

      <ConfirmModal
        title={selectedToken != null ? `Você está prestes a remover "${selectedToken.alias}".` : ""}
        message="A ação não poderá ser desfeita. Você tem certeza?"
        open={openConfirmModal}
        onClose={handleCloseConfirmModal}
        isLoading={loading}
        type="delete"
        onConfirm={() => handleTokenDelete(selectedToken)}
      />
    </PageLayout>
  );
};

export { Tokens };
